import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table, Spinner, Badge, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { AdminService } from '../../services/AdminService';
// import { CustomEditor } from '../CommonComponents';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
// import ReactPlayer from 'react-player';
// import { imageRegex, videoRegex } from '../../helpers/constants';
import { flattenTree } from '../Navigation/HeaderMenu/utilities';
import { FolderFile } from './FolderFile';
import loader from '../../assets/images/loader.gif';
import CustomPagination from '../../common/CustomPagination';
import { delay } from '../CommonComponents';

const defaultPageData = {
  title: "",
  link: "SIDEBAR",
  type: '',
  description: "[]",
  media: "",
  createdby: "",
  createddate: "",
  lastmodifiedby: "",
  lastmodifieddate: "",
  status: 1
}

// function GetMedia({ item }) {
//   const format = item.substring(item.lastIndexOf("."));

//   if (imageRegex.test(format)) {
//     return <img src={item} alt={'news and events'} style={{ maxWidth: '200px' }} />;
//   }

//   if (videoRegex.test(format)) {
//     return <ReactPlayer url={item} controls={true} playing width='300px' height='300px' />;
//   }

//   if (format === ".pdf") {
//     // return <embed src={item} type="application/pdf" width="300" height="300" />;
//     return <p>{item}</p>
//   }
// }

function MedicalPdf() {
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(defaultPageData);
  const [description, setDescription] = useState([]);
  const [media, setMedia] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [link, setLink] = useState('/');
  const [pages, setPages] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState({
    title: '',
    type: '',
    status: '',
  });

  const onFileChange = (e) => {
    // console.log(e);
    // const { files } = e.target;
    setMedia(e);
  };

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: onFileChange,
  //   accept: ['image/*', 'application/pdf', 'video/*'],
  //   multiple: true,
  // })

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/xslx': ['.xlsx', '.xls']
      // 'video/*': [],
    },
    onDrop: onFileChange
  });

  useEffect(() => {
    getData();
    getMenuData();
    // eslint-disable-next-line
  }, []);

  const getData = async (pageNumber = 1) => {
    try {
      const { result, count } = (await AdminService.getPageForPdf(pageNumber - 1, 10, search))?.data;
      setPages(result);
      setCurrentPage(pageNumber);
      setTotalPages(Math.floor(+count / 10) + (count % 10 === 0 ? 0 : 1));

    } catch (e) {
      console.log('error'); setPages([]);
      setCurrentPage(1);
      setTotalPages(0);
    }
  }

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const getMenuData = async () => {
    try {
      const { result: [{ description = '[]' }] } = (await AdminService.getMenu('Medical Sidebar'))?.data;
      setMenuData(flattenTree(JSON.parse(description)));

    } catch (e) {
      console.log('error');

    }
  }

  const handleFolderUpload = async (id, desc) => {
    try {
      for (let i of desc) {
        if (i.uploadMedia.length > 0) {
          const data = (await AdminService.savePageMediaFolder(id, i.uploadMedia))?.data;
          i.uploadMedia = [];
          i.media = [...i.media, ...data.split(',')];
        }

        if (i.children) {
          await handleFolderUpload(id, i.children);
        }
      }
    } catch (e) {
      console.log(e);
    }

    return desc;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = { id: 0 };
    try {
      result = (await AdminService.savePage(page))?.data;

      let medias = result.media;
      if (result?.id && media.length > 0) {
        medias = (await AdminService.savePageMediaFolder(result.id, media))?.data || '';
      }

      let desc = structuredClone(description);
      desc = await handleFolderUpload(result.id, desc);

      await delay(1000);
      await AdminService.savePage({ ...result, description: JSON.stringify(desc), media: medias });

      handleCancel(e);
      await getData();
      Swal.fire({
        icon: 'success',
        title: `Updated successfully`,
        showConfirmButton: false,
        timer: 2000
      });
    } catch (error) {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Submit failed',
        text: error?.response?.data?.message || error?.message || error.toString(),
      })
    }
    setLoading(false);
  }

  const handleCancel = (e) => {
    setMedia([]);
    setActiveTab(1);
    setPage(defaultPageData);
    setDescription([]);
  }

  const handleEdit = (e, data) => {
    e.stopPropagation();
    // const [title, link] = data.title.split('-');
    setPage({ ...defaultPageData, ...data });
    try {
      if (JSON.parse(data.description)) {
        setDescription(JSON.parse(data.description));
      }
    } catch (error) {
      setDescription([]);
    }
    setActiveTab(2);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPage((prevData) => ({
      ...prevData,
      [name]: value,
      // link: getLink(name, value),
    }))
  }

  const handleDescription = (data) => {
    setDescription(data);
  }

  // const getLink = (name, value) => {
  //   if (name === 'title') {
  //     switch (page.type) {
  //       case 'ASRES':
  //         return `/asres/${value}`
  //       case 'MEDICAL':
  //         return `/mc/${value}`
  //       case 'NURSING':
  //         return `/nr/${value}`
  //       case 'PARAMEDICAL':
  //         return `/pm/${value}`
  //       default:
  //         return `/asres/${value}`
  //     }
  //   } else if (name === 'type') {
  //     switch (value) {
  //       case 'ASRES':
  //         return `/asres/${page.title}`
  //       case 'MEDICAL':
  //         return `/mc/${page.title}`
  //       case 'NURSING':
  //         return `/nr/${page.title}`
  //       case 'PARAMEDICAL':
  //         return `/pm/${page.title}`
  //       default:
  //         return `/asres/${value}`
  //     }
  //   }
  // }
  const handleDeleteMedia = async (file) => {
    const name = file.substring(file.lastIndexOf('/') + 1);

    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You will not be able to recover the Media",
      type: "warning",
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
    }).then(async (res) => {
      if (res.value) {
        try {
          await AdminService.deleteMediaFromS3(page.id, name);

          const { result } = (await AdminService.getPage(page.id))?.data;
          setPage(result[0]);

        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Delete failed',
            text: error?.response?.data?.message || error?.message || error.toString(),
          })
        }
      }
    })
  }

  return (
    <>
      <div className='fw-bold'>PDFs</div>
      <hr />
      <Nav pills>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 1 })} onClick={() => {
            setActiveTab(1)
            setPage(defaultPageData);
            setDescription([]);
          }}>
            All Pages
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 2 })} onClick={() => setActiveTab(2)}>
            Add New Page
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1} className={classNames(['fade show'])}>
          <Card body className='my-2'>
            <Form card onSubmit={(e) => {
              e.preventDefault();
              getData();
            }}>
              <Row className="row-cols-lg g-3 align-items-center justify-content-center">
                <Col>
                  <Label
                    className="visually-hidden"
                    for="titleForm"
                  >
                    Title
                  </Label>
                  <Input
                    id="titleForm"
                    name="title"
                    placeholder="Title"
                    type="text"
                    value={search.title}
                    onChange={handleSearch}
                  />
                </Col>
                {/* <Col>
                  <Label
                    className="visually-hidden"
                    for="typeForm"
                  >
                    Page Type
                  </Label>
                  <select id="typeForm" name='type' className='form-select' value={search.type} onChange={handleSearch}>
                    <option value=''>Page Type</option>
                    <option value='ASRES'>ASRES</option>
                    <option value='MEDICAL'>MEDICAL</option>
                    <option value='NURSING'>NURSING</option>
                    <option value='PARAMEDICAL'>PARAMEDICAL</option>
                  </select>
                </Col> */}
                <Col>
                  <Label
                    className="visually-hidden"
                    for="statueForm"
                  >
                    Page Type
                  </Label>
                  <select id="statueForm" name='status' className='form-select' value={search.status} onChange={handleSearch}>
                    <option value=''>Status</option>
                    <option value='1'>ACTIVE</option>
                    <option value='0'>IN ACTIVE</option>
                  </select>
                </Col>
                <Col>
                  <Button color='primary' type='submit' style={{ width: 'inherit' }}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card body>
            <Table borderless striped responsive>
              <thead style={{ background: '#257479', color: '#fff', padding: '10px' }}>
                <tr>
                  <th style={{ padding: '10px' }}>Title</th>
                  <th style={{ padding: '10px' }}>Created By</th>
                  <th style={{ padding: '10px' }}>Date</th>
                  <th style={{ padding: '10px' }}>Status</th>
                  <th style={{ padding: '10px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {pages.map((page) => {
                  return (
                    <tr key={page.id}>
                      <td>{page.title}</td>
                      <td>{page.createdby}</td>
                      <td>{page.createddate}</td>
                      <td><FontAwesomeIcon size='2xs' icon={faCircle} style={{ color: `${+page.status === 1 ? 'green' : 'red'}` }} /></td>
                      <td>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faEye} /></button> */}
                        <button className='btn btn-icon btn-sm' onClick={(e) => handleEdit(e, page)}><FontAwesomeIcon size='xs' icon={faPenToSquare} /></button>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faTrashCan} style={{ color: 'darkred' }} /></button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card>
          <CustomPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={(e, pageNumber) => {
            getData(pageNumber);
          }} />
        </TabPane>
        <TabPane tabId={2} className={classNames(['fade show'])}>
          <Card body>
            <Row className='gap-3'>
              <Col xl={9}>
                <Form>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>Title<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <select id="Pages" name='title' className='form-select' value={page.title} onChange={handleChange}>
                        <option value=''>Select a title</option>
                        {menuData.map((m) => {
                          return (
                            <option value={m.name} key={m.id}>{m.name}</option>
                          )
                        })}
                      </select>
                    </Col>
                  </FormGroup>

                  <FolderFile data={description} handleDescription={handleDescription} pageId={page.id} collapsible removable />

                  <FormGroup row>
                    <Label sm={2} >Media<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p style={{ border: '1px solid', borderRadius: '10px', padding: '20px', background: 'aliceblue', textAlign: 'center' }}>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {media.map((file) => {
                          return (

                            <div key={file.name}>
                              <p>{file.name}</p>
                              {/* {file.type.startsWith('image') && <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '200px' }} />}
                              {file.type === 'application/pdf' && <embed src={URL.createObjectURL(file)} type='application/pdf' width='300' height='300' />}
                              {file.type.startsWith('video') && <ReactPlayer url={URL.createObjectURL(file)} controls={true} playing width='300px' height='300px' />} */}
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={2} >Uploaded media<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {page.media && page.media.split(',').map((file, i) => {
                          return (
                            <div key={i}>
                              {/* <GetMedia item={file} /> */}
                              <p>{file}</p>
                              <Badge color="danger" onClick={() => handleDeleteMedia(file)}>X</Badge>
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup
                    check
                    inline
                  >
                    <Input type="checkbox" checked={+page.status === 1} onChange={(e) => setPage({ ...page, status: e.target.checked ? 1 : 0 })} />
                    <Label check>
                      Check the box to enable the page or uncheck to disable
                    </Label>
                  </FormGroup>
                </Form>

              </Col>
            </Row>
            <br />
            <div>
              <Button style={{ background: '#257479' }} disabled={loading} onClick={handleSubmit}> {loading && <Spinner size='sm' />} Publish </Button>
              {" "}
              <Button color='light' style={{ border: '1px solid' }} disabled={loading} onClick={handleCancel}> Back </Button>
            </div>
          </Card>

        </TabPane>
      </TabContent>
      <Modal isOpen={loading} centered>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ marginTop: '10px' }}>Uploading media, please wait</p>
          <img src={loader} alt='loader' width={200} />
        </div>
      </Modal>
    </>
  )
}

export default MedicalPdf;