import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { AuthService } from "../../services/AuthServices";
import "./styles.css";
import url from "../../assets/images/collegeLogo.png";
import { Link } from "react-router-dom";
import back from "../../assets/images/back.jpg";
import Swal from "sweetalert2";

function Login({ setLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [resetUser, setResetUser] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.login(username, password);
      setLogin(true);
    } catch (e) {
      console.log(e);
      setLogin(false);
    }
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.resetPassword(resetUser);
      setResetUser("");
      setIsLogin(true);
      Swal.fire({
        icon: "success",
        title: `Password reset successful, Please check your mail and login again`,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Reset failed',
        text: error?.response?.data?.message || error?.message || error.toString(),
      })
    }
  };

  return (
    <div
      style={{
        width: "inherit",
        height: "inherit",
        background: `url(${back})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "max-content",
          height: "max-content",
          background: "white",
          margin: "auto",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <div className="login">
          <div className="title">
            <img src={url} alt="logo" width={150} />
            {isLogin ? (
              <>
                <h4>Login</h4>
                <p>Welcome back! please login your account</p>
              </>
            ) : (
              <>
                <h4>Reset Password</h4>
                <p> Please enter your user name to reset the password</p>
              </>
            )}
          </div>
          {isLogin ? (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="username">
                  <strong>Username</strong>
                </Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">
                  <strong>Password</strong>
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </FormGroup>
              <div className="bottom">
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Remember me</Label>
                </FormGroup>
                <Link onClick={() => setIsLogin(false)}>Forget Password</Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Login
                </Button>
              </div>
            </Form>
          ) : (
            <Form onSubmit={handleResetSubmit}>
              <FormGroup>
                <Label for="resetusername">
                  <strong>Username</strong>
                </Label>
                <Input
                  type="text"
                  name="resetUser"
                  id="resetusername"
                  value={resetUser}
                  onChange={(e) => setResetUser(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </FormGroup>
              <div className="bottom">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => setIsLogin(true)}
                >
                  Back to Login
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
