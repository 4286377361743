import axios from "axios";
import { AuthService } from "./AuthServices";

const ADMIN_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ADMIN_SERVICE_PORT + 'website/';
const ADMIN_PAGES_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ADMIN_SERVICE_PORT + 'Pages/';

const getApiHeader = () => {
    const user = AuthService.currentUser();

    const apiHeader = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user?.token,
        }
    }
    return apiHeader;
}

const getApiMediaHeader = () => {
    const user = AuthService.currentUser();

    const apiHeader = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + user?.token,
        }
    }
    return apiHeader;
}

const getBanner = () => {
    const query = {
        "searchQuery": "t.status in (1,0) order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_PAGES_API}listHomePageVideos`, query, getApiHeader());
}

const setBanner = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveHomePageVideos`, data, getApiHeader());
}

const saveBannerVideo = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_PAGES_API}saveHomePageVideosMedia/${id}`, formData, getApiMediaHeader());
}

const deleteHomePageVideosMediaFromS3 = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteHomePageVideosMediaFromS3/${id}/${file}`, getApiMediaHeader());
}

const getAboutUs = () => {
    const query = {
        "searchQuery": "t.status='1' order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listAboutUs`, query, getApiHeader());
}

const setAboutUs = (data) => {
    return axios.post(`${ADMIN_API}saveAboutUs`, data, getApiHeader());
}

const saveMedia = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveAboutUsMedia/${id}`, formData, getApiMediaHeader());
}

const getExplore = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listExplore`, query, getApiHeader());
}

const setExplore = (data) => {
    return axios.post(`${ADMIN_API}saveExplore`, data, getApiHeader());
}


const getCampusLife = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listCampusLife`, query, getApiHeader());
}

const setCampusLife = (data) => {
    return axios.post(`${ADMIN_API}saveCampusLife`, data, getApiHeader());
}

const saveCampusLifeMedia1 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveCampusLifeMedia1/${id}`, formData, getApiMediaHeader());
}

const saveCampusLifeMedia2 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveCampusLifeMedia2/${id}`, formData, getApiMediaHeader());
}

const saveCampusLifeMedia3 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveCampusLifeMedia3/${id}`, formData, getApiMediaHeader());
}

const saveCampusLifeMedia4 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveCampusLifeMedia4/${id}`, formData, getApiMediaHeader());
}

const getUniversityInvestment = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listUniversityInvestment`, query, getApiHeader());
}

const setUniversityInvestment = (data) => {
    return axios.post(`${ADMIN_API}saveUniversityInvestment`, data, getApiHeader());
}

const saveUniversityMainMedia = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveUniversityMainMedia/${id}`, formData, getApiMediaHeader());
}

const saveUniversitySubMedia1 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveUniversitySubMedia1/${id}`, formData, getApiMediaHeader());
}

const saveUniversitySubMedia2 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveUniversitySubMedia2/${id}`, formData, getApiMediaHeader());
}

const saveUniversitySubMedia3 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveUniversitySubMedia3/${id}`, formData, getApiMediaHeader());
}


const getSocialMedia = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listSocialMedia`, query, getApiHeader());
}

const setSocialMedia = (data) => {
    return axios.post(`${ADMIN_API}saveSocialMedia`, data, getApiHeader());
}

const saveSocialMedia1 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveSocialMedia1/${id}`, formData, getApiMediaHeader());
}

const saveSocialMedia2 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveSocialMedia2/${id}`, formData, getApiMediaHeader());
}

const saveSocialMedia3 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveSocialMedia3/${id}`, formData, getApiMediaHeader());
}

const getOurStory = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listOurStory`, query, getApiHeader());
}

const setOurStory = (data) => {
    return axios.post(`${ADMIN_API}saveOurStory`, data, getApiHeader());
}

const saveOurStoryMedia1 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveOurStoryMedia1/${id}`, formData, getApiMediaHeader());
}

const saveOurStoryMedia2 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveOurStoryMedia2/${id}`, formData, getApiMediaHeader());
}

const saveOurStoryMedia3 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveOurStoryMedia3/${id}`, formData, getApiMediaHeader());
}

const saveOurStoryMedia4 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveOurStoryMedia4/${id}`, formData, getApiMediaHeader());
}

const getGroupColleges = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listGroupColleges`, query, getApiHeader());
}

const setGroupColleges = (data) => {
    return axios.post(`${ADMIN_API}saveGroupColleges`, data, getApiHeader());
}

const saveGroupCollegeMedia = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGroupCollegeMedia/${id}`, formData, getApiMediaHeader());
}

const getGoverningBody = () => {
    const query = {
        "searchQuery": "t.status=1 order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 10
    }
    return axios.post(`${ADMIN_API}listGoverningBody`, query, getApiHeader());
}

const setGoverningBody = (data) => {
    return axios.post(`${ADMIN_API}saveGoverningBody`, data, getApiHeader());
}

const saveGoverningBodyMedia1 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia1/${id}`, formData, getApiMediaHeader());
}

const saveGoverningBodyMedia2 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia2/${id}`, formData, getApiMediaHeader());
}

const saveGoverningBodyMedia3 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia3/${id}`, formData, getApiMediaHeader());
}

const saveGoverningBodyMedia4 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia4/${id}`, formData, getApiMediaHeader());
}

const saveGoverningBodyMedia5 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia5/${id}`, formData, getApiMediaHeader());
}

const saveGoverningBodyMedia6 = (id, file) => {
    const formData = new FormData();
    formData.append('media', file, file.name);
    return axios.post(`${ADMIN_API}saveGoverningBodyMedia6/${id}`, formData, getApiMediaHeader());
}

const getMenus = () => {
    const query = {
        "searchQuery": "t.status in (1,0) order by t.lastmodifieddate DESC",
        "pageIndex": 0,
        "maxResults": 100
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsiteMenu`, query, getApiHeader());
}

const getMenu = (title) => {
    const query = {
        "searchQuery": `t.title='${title}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsiteMenu`, query, getApiHeader());
}

const saveMenu = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveWebsiteMenu`, data, getApiHeader());
}

const getPage = (id) => {
    const query = {
        "searchQuery": `t.status in (1,0) AND t.id='${id}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsitePage`, query, getApiHeader());
}

const getPages = (page = 0, count = 10, search = {}) => {
    let searchQuery = `t.link NOT IN ('GALLERY', 'SIDEBAR', 'POPUP') `;

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.status === '' || search.status === undefined) {
        searchQuery += ` AND t.status in (1, 0) `;
    } else {
        searchQuery += ` AND t.status in (${search.status}) `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsitePage`, query, getApiHeader());
}

const getPageForPdf = (page = 0, count = 10, search = {}) => {
    let searchQuery = `t.link='SIDEBAR' `;

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.status === '' || search.status === undefined) {
        searchQuery += ` AND t.status in (1, 0) `;
    } else {
        searchQuery += ` AND t.status in (${search.status}) `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsitePage`, query, getApiHeader());
}

const getGalleryPages = (page = 0, count = 10, search = {}) => {
    let searchQuery = `t.link='GALLERY' `;

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.status === '' || search.status === undefined) {
        searchQuery += ` AND t.status in (1, 0) `;
    } else {
        searchQuery += ` AND t.status in (${search.status}) `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsitePage`, query, getApiHeader());
}

const getPopupPages = (page = 0, count = 10, search = {}) => {
    let searchQuery = `t.link='POPUP' `;

    if (search.title) {
        searchQuery += ` AND t.description LIKE '%${search.title}%' `;
    }

    if (search.status === '') {
        searchQuery += ` AND t.status in (1, 0) `;
    } else {
        searchQuery += ` AND t.status in (${search.status}) `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsitePage`, query, getApiHeader());
}

const savePage = (data) => {
    console.log(data);
    return axios.post(`${ADMIN_PAGES_API}saveWebsitePage`, data, getApiHeader());
}

const savePageMedia = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        formData.append('media', f, f.name);
    }
    return axios.post(`${ADMIN_PAGES_API}saveWebsitePageMedia/${id}`, formData, getApiMediaHeader());
}

const savePageMediaFolder = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        if(f?.name) {
            formData.append('media', f, f.name);
        }
    }
    return axios.post(`${ADMIN_PAGES_API}saveWebsitePageMedia/${id}?folder=true`, formData, getApiMediaHeader());
}

const getNewsEvent = (id) => {
    const query = {
        "searchQuery": `t.status in (1,0) AND t.id='${id}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listNewsEvents`, query, getApiHeader());
}

const getNewsEvents = (page = 0, count = 10, search = {}) => {
    let searchQuery = '';
    if (search.status === '' || search.status === undefined) {
        searchQuery += ` t.status in (1, 0) `;
    } else {
        searchQuery += ` t.status in (${search.status}) `;
    }

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listNewsEvents`, query, getApiHeader());
}

const saveNewsEvents = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveNewsEvents`, data, getApiHeader());
}

const saveNewsEventsMedia = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        formData.append('media', f, f.name);
    }
    return axios.post(`${ADMIN_PAGES_API}saveNewsEventsMedia/${id}`, formData, getApiMediaHeader());
}

const deleteFileNewsAndEventsFromS3 = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteFileNewsAndEventsFromS3/${id}/${file}`, getApiMediaHeader());
}

const getAward = (id) => {
    const query = {
        "searchQuery": `t.status in (1,0) AND t.id='${id}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsiteAwards`, query, getApiHeader());
}

const getAwards = (page = 0, count = 10, search = {}) => {
    let searchQuery = '';
    if (search.status === '' || search.status === undefined) {
        searchQuery += ` t.status in (1, 0) `;
    } else {
        searchQuery += ` t.status in (${search.status}) `;
    }

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listWebsiteAwards`, query, getApiHeader());
}

const saveAwards = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveWebsiteAwards`, data, getApiHeader());
}

const saveAwardsMedia = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        formData.append('media', f, f.name);
    }
    return axios.post(`${ADMIN_PAGES_API}saveWebsiteAwardsMedia/${id}`, formData, getApiMediaHeader());
}

const deleteWebsiteAwardsMediaFromS3 = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteWebsiteAwardsMediaFromS3/${id}/${file}`, getApiMediaHeader());
}

const deleteMedia = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteFile/${id}/${file}`, getApiMediaHeader());
}

const deleteMediaFromS3 = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteFileWebsitePageFromS3/${id}/${file}`, getApiMediaHeader());
}

const savePublications = (data) => {
    return axios.post(`${ADMIN_PAGES_API}savePublications`, data, getApiHeader());
}

const getPublications = (page = 0, count = 10, search = {}) => {
    let searchQuery = '';
    if (search.status === '' || search.status === undefined) {
        searchQuery += ` t.status in (1, 0) `;
    } else {
        searchQuery += ` t.status in (${search.status}) `;
    }

    if (search.title) {
        searchQuery += ` AND t.title LIKE '%${search.title}%' `;
    }

    if (search.type) {
        searchQuery += ` AND t.type='${search.type}' `
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listPublications`, query, getApiHeader());
}

const getPublication = (id) => {
    const query = {
        "searchQuery": `t.status in (1,0) AND t.id='${id}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listPublications`, query, getApiHeader());
}

const savePublicationMedia = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        formData.append('media', f, f.name);
    }
    return axios.post(`${ADMIN_PAGES_API}savePublicationsMedia/${id}`, formData, getApiMediaHeader());
}

const saveCareers = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveCareers`, data, getApiHeader());
}

const getCareers = (page = 0, count = 10) => {
    const query = {
        "searchQuery": "t.status in (1,0) order by t.lastmodifieddate DESC",
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listCareers`, query, getApiHeader());
}

const getCareer = (id) => {
    const query = {
        "searchQuery": `t.status in (1,0) AND t.id='${id}' order by t.lastmodifieddate DESC`,
        "pageIndex": 0,
        "maxResults": 1
    }
    return axios.post(`${ADMIN_PAGES_API}listCareers`, query, getApiHeader());
}

const saveCareersMedia = (id, file) => {
    const formData = new FormData();
    for (const f of file) {
        formData.append('media', f, f.name);
    }
    return axios.post(`${ADMIN_PAGES_API}saveCareersMedia/${id}`, formData, getApiMediaHeader());
}

const deleteCareersMediaFromS3 = (id, file) => {
    return axios.delete(`${ADMIN_PAGES_API}deleteCareersMediaFromS3/${id}/${file}`, getApiMediaHeader());
}

const getJobs = (page = 0, count = 10) => {
    const query = {
        "searchQuery": "t.status in (1,0) order by t.lastmodifieddate DESC",
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listAppliedJobs`, query, getApiHeader());
}

const getGrievance = (page = 0, count = 10, search) => {
    let searchQuery = ``;

    if (search.status === '' || search.status === undefined) {
        searchQuery += `t.status in (1, 0) `;
    } else {
        searchQuery += `t.status in (${search.status}) `;
    }

    if (search.name) {
        searchQuery += ` AND t.name LIKE '%${search.name}%' `;
    }
    if (search.rollNumber) {
        searchQuery += ` AND t.rollNumber LIKE '%${search.rollNumber}%' `;
    }
    if (search.email) {
        searchQuery += ` AND t.email LIKE '%${search.email}%' `;
    }
    if (search.phoneNumber) {
        searchQuery += ` AND t.phoneNumber LIKE '%${search.phoneNumber}%' `;
    }
    const query = {
        "searchQuery": `${searchQuery} order by t.lastmodifieddate DESC`,
        "pageIndex": page,
        "maxResults": count
    }
    return axios.post(`${ADMIN_PAGES_API}listGrievance`, query, getApiHeader());
}

const saveGrievance = (data) => {
    return axios.post(`${ADMIN_PAGES_API}saveGrievance`, data, getApiHeader());
}

export const AdminService = {
    getBanner,
    setBanner,
    saveBannerVideo,
    deleteHomePageVideosMediaFromS3,

    getAboutUs,
    setAboutUs,
    saveMedia,

    getExplore,
    setExplore,

    getCampusLife,
    setCampusLife,
    saveCampusLifeMedia1,
    saveCampusLifeMedia2,
    saveCampusLifeMedia3,
    saveCampusLifeMedia4,

    getUniversityInvestment,
    setUniversityInvestment,
    saveUniversityMainMedia,
    saveUniversitySubMedia1,
    saveUniversitySubMedia2,
    saveUniversitySubMedia3,

    getSocialMedia,
    setSocialMedia,
    saveSocialMedia1,
    saveSocialMedia2,
    saveSocialMedia3,

    getOurStory,
    setOurStory,
    saveOurStoryMedia1,
    saveOurStoryMedia2,
    saveOurStoryMedia3,
    saveOurStoryMedia4,

    getGroupColleges,
    setGroupColleges,
    saveGroupCollegeMedia,

    getGoverningBody,
    setGoverningBody,
    saveGoverningBodyMedia1,
    saveGoverningBodyMedia2,
    saveGoverningBodyMedia3,
    saveGoverningBodyMedia4,
    saveGoverningBodyMedia5,
    saveGoverningBodyMedia6,

    getMenus,
    getMenu,
    saveMenu,

    getPage,
    getPages,
    getGalleryPages,
    getPageForPdf,
    getPopupPages,
    savePage,
    savePageMedia,
    savePageMediaFolder,

    getNewsEvent,
    getNewsEvents,
    saveNewsEvents,
    saveNewsEventsMedia,
    deleteFileNewsAndEventsFromS3,

    getAward,
    getAwards,
    saveAwards,
    saveAwardsMedia,
    deleteWebsiteAwardsMediaFromS3,

    deleteMedia,
    deleteMediaFromS3,

    getPublications,
    getPublication,
    savePublications,
    savePublicationMedia,

    getCareers,
    getCareer,
    saveCareers,
    saveCareersMedia,
    deleteCareersMediaFromS3,

    getJobs,

    getGrievance,
    saveGrievance,
}