export const defaultMenus = [
    {
        id: 1,
        name: 'Top Menu'
    },
    {
        id: 2,
        name: 'Header Menu',
    },
    {
        id: 9,
        name: 'Footer Menu',
    },
    {
        id: 3,
        name: 'Medical Top Menu'
    },
    {
        id: 4,
        name: 'Medical Header Menu',
    },
    {
        id: 10,
        name: 'Medical Footer Menu',
    },
    {
        id: 5,
        name: 'Nursing Top Menu'
    },
    {
        id: 6,
        name: 'Nursing Header Menu',
    },
    {
        id: 11,
        name: 'Nursing Footer Menu',
    },
    {
        id: 7,
        name: 'Paramedical Top Menu'
    },
    {
        id: 8,
        name: 'Paramedical Header Menu',
    },
    {
        id: 12,
        name: 'Paramedical Footer Menu',
    },
    {
        id: 13,
        name: 'Medical SideBar',
    }
]


export const headerMenuItems = [
    {
        id: 1,
        name: "Home",
        editable: false,
        disable: false,
        link: "/",
        children: [],
    },
    {
        id: 2,
        name: "ABOUT US",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 3,
                name: "About Us",
                editable: false,
                disable: false,
                link: "/aboutUs",
                children: [],
            },
            {
                id: 4,
                name: "Chairman",
                editable: false,
                disable: false,
                link: "/chairman",
                children: [],
            },
        ],
    },
    {
        id: 5,
        name: "INSTITUTIONS",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 6,
                name: "ASRAM MEDICAL COLLEGE",
                editable: false,
                disable: false,
                link: "/mcLanding",
                children: [],
            },
        ],
    },
    {
        id: 7,
        name: "ASRES GROUP ENTITIES",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 8,
                name: "Department",
                editable: false,
                disable: false,
                link: "/mcDepartment",
                children: [],
            },
            {
                id: 9,
                name: "NAAC",
                editable: false,
                disable: false,
                link: "/naac",
                children: [],
            },
            {
                id: 10,
                name: "Under Graduate",
                editable: false,
                disable: false,
                link: "/ugPage",
                children: [],
            },
            {
                id: 11,
                name: "Institutional",
                editable: false,
                disable: false,
                link: "/institutional",
                children: [],
            },
        ],
    },
    {
        id: 12,
        name: "CAMPUS LIFE",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 13,
                name: "Campus Facilities",
                editable: false,
                disable: false,
                link: "/campusLife#campusFacilities",
                children: [],
            },
            {
                id: 14,
                name: "Auditorium",
                editable: false,
                disable: false,
                link: "/campusLife#auditorium",
                children: [],
            },
            {
                id: 15,
                name: "Student Affairs",
                editable: false,
                disable: false,
                link: "/campusLife#studentAffairs",
                children: [],
            },
            {
                id: 16,
                name: "Library",
                editable: false,
                disable: false,
                link: "/campusLife#library",
                children: [],
            },
            {
                id: 17,
                name: "Sports",
                editable: false,
                disable: false,
                link: "/campusLife#sports",
                children: [],
            },
            {
                id: 18,
                name: "Hostel",
                editable: false,
                disable: false,
                link: "/campusLife#hostel",
                children: [],
            },
            {
                id: 19,
                name: "Mess",
                editable: false,
                disable: false,
                link: "/campusLife#mess",
                children: [],
            },
            {
                id: 20,
                name: "Security",
                editable: false,
                disable: false,
                link: "/campusLife#security",
                children: [],
            },
            {
                id: 21,
                name: "Student Centric Methods",
                editable: false,
                disable: false,
                link: "/campusLife#studentCentricMethods",
                children: [],
            },
        ],
    },
    {
        id: 22,
        name: "PLACEMENTS",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 23,
                name: "Action",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 24,
                name: "Another Action",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 25,
                name: "Something",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
        ],
    },
    {
        id: 26,
        name: "CONNECT",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 27,
                name: "Action1",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 28,
                name: "Another Action1",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 29,
                name: "Something1",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
        ],
    },
    {
        id: 30,
        name: "RESEARCH",
        editable: false,
        disable: false,
        link: "",
        children: [
            {
                id: 31,
                name: "Action2",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 32,
                name: "Another Action2",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
            {
                id: 33,
                name: "Something2",
                editable: false,
                disable: false,
                link: "/",
                children: [],
            },
        ],
    },
];

export const defaultPages = [
    {
        name: 'Sub Menu',
        link: '',
    },
    {
        name: 'Custom Page',
        link: '#',
    },
    {
        name: 'Home',
        link: '/',
    },
    {
        name: 'About Us',
        link: '/aboutUs',
    },
    {
        name: 'Chairman',
        link: '/chairman',
    },
    {
        name: 'Campus Life',
        link: '/campusLife',
    },
    {
        name: 'Campus Life - Facilities',
        link: '/campusLife#campusFacilities',
    },
    {
        name: 'Campus Life - Auditorium',
        link: '/campusLife#auditorium',
    },
    {
        name: 'Campus Life - Student Affairs',
        link: '/campusLife#studentAffairs',
    },
    {
        name: 'Campus Life - Library',
        link: '/campusLife#library',
    },
    {
        name: 'Campus Life - Sports',
        link: '/campusLife#sports',
    },
    {
        name: 'Campus Life - Hostel',
        link: '/campusLife#hostel',
    },
    {
        name: 'Campus Life - Mess',
        link: '/campusLife#mess',
    },
    {
        name: 'Campus Life - Security',
        link: '/campusLife#security',
    },
    {
        name: 'Campus Life - Student Centric Methods',
        link: '/campusLife#studentCentricMethods',
    },
    {
        name: 'Departments',
        link: '/departments',
    },
    {
        name: 'News and Events',
        link: '/newsEvents',
    },
    {
        name: 'Governing Body',
        link: '/governingBody',
    },
    {
        name: 'Medical Landing Page',
        link: '/mcLanding',
    },
    {
        name: 'NAAC',
        link: '/naac',
    },
    {
        name: 'Medical Department',
        link: '/mcDepartment',
    },
    {
        name: 'Nursing Landing Page',
        link: '/nursingDepartment',
    },
    {
        name: 'Paramedical Landing Page',
        link: '/paramedicalDepartment',
    },
    {
        name: 'UG Page',
        link: '/ugPage',
    },
    {
        name: 'A.1.16',
        link: '/mcInstitutional',
    },
    {
        name: 'Vision & Mission',
        link: '/visionMission',
    },
    {
        name: 'Award & Achievement',
        link: '/awardAchievement',
    },
    {
        name: 'MC Award & Achievement',
        link: '/mcAwardAchievement',
    },
    {
        name: 'MC IQAC',
        link: '/mcIqac',
    },
    {
        name: 'MC Committees',
        link: '/mcCommittees',
    },
    {
        name: 'MC CBME',
        link: '/mcCbme',
    },
    {
        name: 'MC MEU',
        link: '/mcMeu',
    },
    {
        name: 'MC Admissions',
        link: '/mcAdmissions',
    },
    {
        name: 'MC MARB',
        link: '/mcFiles/MARB',
    },
    {
        name: 'College News Details',
        link: '/collegeNewsDetail',
    },
    {
        name: 'Gallery Collection',
        link: '/galleryCollection'
    }
]

export const menuData = {
    title: "",
    description: "[]",
    media: "",
    createdby: "",
    createddate: "",
    lastmodifiedby: "",
    lastmodifieddate: "",
    status: 1,
    website_page_id: '',
  }