import { HashRouter } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useState } from 'react';
import Sidebar from './components/sidebar';
import Main from './components/Main';
import { AuthService } from './services/AuthServices';
import Login from './components/Login';

function App() {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [isLogin, setIsLogin] = useState(AuthService.isUserLoggedIn());
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const handleLogin = (value) => {
    setIsLogin(value);
  }
  return (
    <HashRouter>
      <div className="App">
        {isLogin ? (
          <>
            <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
            <Main toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} setLogin={handleLogin} />
          </>
        ) : (
          <Login setLogin={handleLogin} />
        )}
      </div>
    </HashRouter>
  );
}

export default App;
