// import Footer from './Footer';
import LandingPage from './LandingPage';
// import Media from './Media';
import Navigation from './Navigation';
import Pages from './Pages';
// import Settings from './Settings';
// import Content from './Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    // faClipboardCheck, faGaugeHigh, faGear, 
    faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { 
    // faCirclePlay, 
    faCopy, faRectangleList, 
    // faSquareMinus
 } from '@fortawesome/free-regular-svg-icons';
import NewsEvents from './NewsEvents';
import Awards from './Awards';
import Gallery from './Gallery';
import Publications from './Publications';
import Careers from './Careers';
import MedicalPdf from './MedicalPdf';
import Popup from './Popup';
import Grievance from './Grievance';

export const routesData = [
    // {
    //     title: 'Dashboard',
    //     icon: <FontAwesomeIcon icon={faGaugeHigh} />,
    //     link: '/',
    //     Component: <Navigation />,
    // },
    {
        title: 'Navigation',
        icon: <FontAwesomeIcon icon={faLocationArrow} />,
        link: '/navigation',
        Component: <Navigation />,
    },
    {
        title: 'Landing Page',
        icon: <FontAwesomeIcon icon={faRectangleList} />,
        link: '/landingPage',
        Component: <LandingPage />,
    },
    // {
    //     title: 'Content',
    //     icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    //     link: '/content',
    //     Component: <Content />,
    // },
    {
        title: 'Pages',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/pages',
        Component: <Pages />,
    },
    {
        title: 'News & Events',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/newsEvents',
        Component: <NewsEvents />,
    },
    {
        title: 'Awards',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/awards',
        Component: <Awards />,
    },
    {
        title: 'Gallery',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/gallery',
        Component: <Gallery />,
    },
    {
        title: 'Popup',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/popup',
        Component: <Popup />,
    },
    {
        title: 'Publications',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/publications',
        Component: <Publications />,
    },
    {
        title: 'Careers',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/careers',
        Component: <Careers />,
    },
    {
        title: 'IQAC-NAAC',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/iqacNaacDetails',
        Component: <MedicalPdf />,
    },
    {
        title: 'Grievance',
        icon: <FontAwesomeIcon icon={faCopy} />,
        link: '/grievance',
        Component: <Grievance />,
    }
    // {
    //     title: 'Media',
    //     icon: <FontAwesomeIcon icon={faCirclePlay} />,
    //     link: '/media',
    //     Component: <Media />,
    // },
    // {
    //     title: 'Footer',
    //     icon: <FontAwesomeIcon icon={faSquareMinus} />,
    //     link: '/footer',
    //     Component: <Footer />,
    // },
    // {
    //     title: 'Settings',
    //     icon: <FontAwesomeIcon icon={faGear} />,
    //     link: '/settings',
    //     Component: <Settings />,
    // },
]