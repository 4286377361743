import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { AdminService } from '../../services/AdminService';
import Swal from 'sweetalert2';
import CustomPagination from '../../common/CustomPagination';

const defaultPageData = {
  // id: 1,
  name: "",
  ugOrPg: "",
  yearOfStudy: "",
  rollNumber: "",
  email: "",
  phoneNumber: "",
  yourGrievance: "",
  status: 1,
  link: "/mc/Grievance Redressal Committee",
  type: "MEDICAL",
};

function Grievance() {
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(defaultPageData);
  const [loading, setLoading] = useState(false);
  // const [link, setLink] = useState('/');
  const [pages, setPages] = useState([]);


  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState({
    name: '',
    rollNumber: '',
    email: '',
    phoneNumber : '',
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async (pageNumber = 1) => {
    try {
      const { result, count } = (await AdminService.getGrievance(pageNumber - 1, 10, search))?.data;
      setPages(result);
      setCurrentPage(pageNumber);
      setTotalPages(Math.floor(+count / 10) + (count % 10 === 0 ? 0 : 1));

    } catch (e) {
      console.log('error');
      setPages([]);
      setCurrentPage(1);
      setTotalPages(0);
    }
  }

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // await AdminService.saveGrievance({ ...page });
      handleCancel(e);
      await getData();
      Swal.fire({
        icon: 'success',
        title: `Updated successfully`,
        showConfirmButton: false,
        timer: 2000
      });
    } catch (error) {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Submit failed',
        text: error?.response?.data?.message || error?.message || error.toString(),
      })
    }
    setLoading(false);
  }

  const handleCancel = (e, tab = 1) => {
    setActiveTab(tab);
    setPage(defaultPageData);
  }

  const handleEdit = (e, data) => {
    e.stopPropagation();
    setPage({ ...defaultPageData, ...data });
    setActiveTab(2);
  }

  const handleChange = (e) => {
    // const { name, value } = e.target;

    // setPage((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }))
  }

  return (
    <>
      <div className='fw-bold'>Grievance</div>
      <hr />
      <Nav pills>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 1 })} onClick={(e) => { setActiveTab(1); handleCancel(e); }}>
            All Grievance
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 2 })} onClick={(e) => { setActiveTab(2); handleCancel(e, 2); }}>
            View Grievance
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1} className={classNames(['fade show'])}>
          <Card body className='my-2'>
            <Form card onSubmit={(e) => {
              e.preventDefault();
              getData();
            }}>
              <Row className="row-cols-lg g-3 align-items-center justify-content-center">
                <Col>
                  <Label
                    className="visually-hidden"
                    for="titleForm"
                  >
                    Name
                  </Label>
                  <Input
                    id="titleForm"
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={search.name}
                    onChange={handleSearch}
                  />
                </Col>
                <Col>
                  <Label
                    className="visually-hidden"
                    for="rollNumberForm"
                  >
                    Roll Number
                  </Label>
                  <Input
                    id="rollNumberForm"
                    name="rollNumber"
                    placeholder="Roll Number"
                    type="text"
                    value={search.rollNumber}
                    onChange={handleSearch}
                  />
                </Col>
                <Col>
                  <Label
                    className="visually-hidden"
                    for="emailForm"
                  >
                    Email
                  </Label>
                  <Input
                    id="emailForm"
                    name="email"
                    placeholder="Email"
                    type="text"
                    value={search.email}
                    onChange={handleSearch}
                  />
                </Col>
                <Col>
                  <Label
                    className="visually-hidden"
                    for="phoneNumberForm"
                  >
                    Phone Number
                  </Label>
                  <Input
                    id="phoneNumberForm"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    type="text"
                    value={search.phoneNumber}
                    onChange={handleSearch}
                  />
                </Col>
                <Col>
                  <Label
                    className="visually-hidden"
                    for="statueForm"
                  >
                    Page Type
                  </Label>
                  <select id="statueForm" name='status' className='form-select' value={search.status} onChange={handleSearch}>
                    <option value=''>Status</option>
                    <option value='1'>ACTIVE</option>
                    <option value='0'>IN ACTIVE</option>
                  </select>
                </Col>
                <Col>
                  <Button color='primary' type='submit' style={{ width: 'inherit' }}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card body>
            <Table borderless striped responsive>
              <thead style={{ background: '#257479', color: '#fff', padding: '10px' }}>
                <tr>
                  <th style={{ padding: '10px' }}>Name</th>
                  <th style={{ padding: '10px' }}>Year of Study</th>
                  <th style={{ padding: '10px' }}>Email</th>
                  <th style={{ padding: '10px' }}>Phone Number</th>
                  <th style={{ padding: '10px' }}>Date</th>
                  <th style={{ padding: '10px' }}>Status</th>
                  <th style={{ padding: '10px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {pages.map((page) => {
                  return (
                    <tr key={page.id}>
                      <td>{page.name}</td>
                      <td>{page.yearOfStudy}</td>
                      <td>{page.email}</td>
                      <td>{page.phoneNumber}</td>
                      <td>{page.createddate}</td>
                      <td><FontAwesomeIcon size='2xs' icon={faCircle} style={{ color: `${+page.status === 1 ? 'green' : 'red'}` }} /></td>
                      <td>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faEye} /></button> */}
                        <button className='btn btn-icon btn-sm' onClick={(e) => handleEdit(e, page)}><FontAwesomeIcon size='xs' icon={faEye} /></button>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faTrashCan} style={{ color: 'darkred' }} /></button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card>
          <CustomPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={(e, pageNumber) => {
            getData(pageNumber);
          }} />
        </TabPane>
        <TabPane tabId={2} className={classNames(['fade show'])}>
          <Card body>
            <Row className='gap-3'>
              <Col xl={9}>
                <Form>
                  <FormGroup row>
                    <Label for="exampleName" sm={2}>Name<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="exampleName"
                        name="name"
                        placeholder="Enter Name here"
                        type="text"
                        value={page.name}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleugOrPg" sm={2}>UG or PG<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="exampleugOrPg"
                        name="ugOrPg"
                        placeholder="Enter ugOrPg here"
                        type="text"
                        value={page.ugOrPg}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="yearOfStudy" sm={2}>Year of Study<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="yearOfStudy"
                        name="yearOfStudy"
                        placeholder="Enter yearOfStudy here"
                        type="text"
                        value={page.yearOfStudy}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="rollNumber" sm={2}>Roll number<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="rollNumber"
                        name="rollNumber"
                        placeholder="Enter rollNumber here"
                        type="text"
                        value={page.rollNumber}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="email" sm={2}>Email<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="email"
                        name="email"
                        placeholder="Enter email here"
                        type="email"
                        value={page.email}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="phoneNumber" sm={2}>Phone Number<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter phoneNumber here"
                        type="email"
                        value={page.phoneNumber}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="yourGrievance" sm={2}>Grievance<span className="text-danger">*</span></Label>
                    <Col sm={10}>
                      <Input
                        id="yourGrievance"
                        name="yourGrievance"
                        placeholder="Enter Grievance here"
                        type="textarea"
                        value={page.yourGrievance}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    check
                    inline
                  >
                    <Input type="checkbox" disabled checked={+page.status === 1} onChange={(e) => setPage({ ...page, status: e.target.checked ? 1 : 0 })} />
                    <Label check>
                      Check the box to enable the page or uncheck to disable
                    </Label>
                  </FormGroup>
                </Form>

              </Col>
            </Row>
            <br />
            <div>
              <Button style={{ background: '#257479' }} disabled onClick={handleSubmit}> {loading && <Spinner size='sm' />} Submit </Button>
              {" "}
              <Button color='light' style={{ border: '1px solid' }} disabled={loading} onClick={handleCancel}> Back </Button>
            </div>
          </Card>
        </TabPane>
      </TabContent>
    </>
  )
}

export default Grievance;