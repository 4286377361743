import React, { Suspense, lazy } from 'react'
import { Card, 
  // Nav, NavItem, NavLink, 
  TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
const Banner = lazy(() => import('./Banner'));
const AboutUs = lazy(() => import('./AboutUs'));
const ExploreSection = lazy(() => import('./ExploreSection'));
const GroupOfColleges = lazy(() => import('./GroupOfColleges'));
const GovernmentBody = lazy(() => import('./GovernmentBody'));
const UniversityInvestments = lazy(() => import('./UniversityInvestments'));
const OurStory = lazy(() => import('./OurStory'));
// const Portal = lazy(() => import('./Portal'));
const SocialMedia = lazy(() => import('./SocialMedia'));
const CampusLife = lazy(() => import('./CampusLife'));

function LandingPage() {
  // const [activeTab, setActiveTab] = useState(1);

  return (
    <Suspense fallback={<div>Loading</div>}>
      <div className='fw-bold'>Landing Page</div>
      <hr />
      {/* <Nav pills>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 1 })} onClick={() => setActiveTab(1)}>
            Add New Page
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 2 })} onClick={() => setActiveTab(2)}>
            Edit
          </NavLink>
        </NavItem>
      </Nav> */}
      {/* <hr /> */}
      <TabContent activeTab={1}>
        <TabPane tabId={1} className={classNames(['fade show'])}>
          <Card body>
            <Banner />
            <hr />
            <AboutUs />
            <hr />
            <ExploreSection />
            <hr />
            <GroupOfColleges />
            <hr />
            <GovernmentBody />
            <hr />
            <UniversityInvestments />
            <hr />
            <OurStory />
            {/* <hr />
            <Portal /> */}
            <hr />
            <SocialMedia />
            <hr />
            <CampusLife />
          </Card>
        </TabPane>
        <TabPane tabId={2} className={classNames(['fade show'])}></TabPane>
      </TabContent>

    </Suspense>
  )
}

export default LandingPage;