import classNames from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import collegeLogo from '../../assets/images/collegeLogo.png'
import { routesData } from '../routes'

function Sidebar({ isOpen, toggle }) {
    const location = useLocation();
    return (
        <div className={classNames("sidebar", { "is-open": isOpen })} style={{ display: `${isOpen ? '': 'none'}`}}>
            <div className="sidebar-header">
                <span color="info" onClick={toggle} style={{ color: "#000" }}>
                    &times;
                </span>
                <img src={collegeLogo} height={100} alt="Logo" />
            </div>
            <hr className='text-dark' />
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    {routesData.map((routeLink) => {
                        return (
                            <NavItem key={routeLink.title} className='sidebar-item'>
                                <NavLink className={`${location.pathname === routeLink.link ? 'current-target' : ''}`} tag={Link} to={routeLink.link}>
                                   {routeLink.icon} {routeLink.title}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
            </div>
        </div>
    )
}

export default Sidebar