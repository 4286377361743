import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table, Spinner, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { AdminService } from '../../services/AdminService';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';
import { imageRegex, videoRegex } from '../../helpers/constants';
import { CustomEditor } from '../CommonComponents';
import CustomPagination from '../../common/CustomPagination';

const defaultPageData = {
  jobTitle: "",
  jobRole: "",
  noticePeriod: "",
  jobPostingDate: "",
  skills: "",
  jobDescription: "",
  experience: 0,
  media: "",
  type: '',

  createdby: "",
  createddate: "",
  lastmodifiedby: "",
  lastmodifieddate: "",
  status: 1
}

const defaultAppliedData = {
  careerId: 123,
  email: "",
  firstname: "",
  lastname: "",
  experience: "",
  mobilenumber: "",
  resumeUrl: "",
  type: '',
}
// const departments = ['Anatomy', 'Physiology', 'Anesthesiology', 'Ophthalmology', 'Psychiatry', 'Pulmonary Medicine', 'Forensic Medicine', 'BioChemistry', 'Pathology', 'Pediatrics', 'MicroBiology', 'CommunityMedicine', 'EarNoseThrot', 'Dermatology', 'RadioDiagnosis', 'Pharmacology', 'OBGYN', 'Orthopaedics', 'GeneralSurgery', 'EmergencyMedicine'];

function GetMedia({ item }) {
  const format = item.substring(item.lastIndexOf("."));

  if (imageRegex.test(format)) {
    return <img src={item} alt={'news and events'} style={{ maxWidth: '200px' }} />;
  }

  if (videoRegex.test(format)) {
    return <ReactPlayer url={item} controls={true} playing width='300px' height='300px' />;
  }

  if (format === ".pdf") {
    return <embed src={item} type="application/pdf" width="300" height="300" />;
  }
}

function Careers() {
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(defaultPageData);
  const [media, setMedia] = useState([]);
  // const [link, setLink] = useState('/');
  const [pages, setPages] = useState([]);
  const [appliedList, setAppliedList] = useState([]);
  const [application, setApplication] = useState(defaultAppliedData);
  const [loading, setLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalApplicationPages, setTotalApplicationPages] = useState(0);
  const [currentApplicationPage, setCurrentApplicationPage] = useState(1);

  const onFileChange = (e) => {
    // console.log(e);
    // const { files } = e.target;
    setMedia(e);
  };

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: onFileChange,
  //   accept: ['image/*', 'application/pdf', 'video/*'],
  //   multiple: true,
  // })

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/*': [],
      // 'application/pdf': [],
      // 'video/*': [],
    },
    onDrop: onFileChange,
    multiple: false,
  });

  useEffect(() => {
    getData();
    getApplicationList();
  }, []);

  const getData = async (pageNumber = 1) => {
    try {
      const { result, count } = (await AdminService.getCareers(pageNumber - 1, 10))?.data;
      setPages(result);
      setCurrentPage(pageNumber);
      setTotalPages(Math.floor(+count / 10) + (count % 10 === 0 ? 0 : 1));

    } catch (e) {
      console.log(e);
    }
  }

  const getApplicationList = async (pageNumber = 1) => {
    try {
      const { result, count } = (await AdminService.getJobs(pageNumber-1, 10))?.data;
      setAppliedList(result);
      setCurrentApplicationPage(pageNumber);
      setTotalApplicationPages(Math.floor(+count / 10) + (count % 10 === 0 ? 0 : 1));
    } catch(e) {
      console.log(e);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = { id: 0 };
    try {
      result = (await AdminService.saveCareers(page))?.data;

      if (result?.id && media.length > 0) {
        await AdminService.saveCareersMedia(result.id, media);
      }

      handleCancel(e);
      await getData();
      Swal.fire({
        icon: 'success',
        title: `Updated successfully`,
        showConfirmButton: false,
        timer: 2000
      });
    } catch (error) {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Submit failed',
        text: error?.response?.data?.message || error?.message || error.toString(),
      })
    }
    setLoading(false);
  }

  const handleCancel = (e) => {
    // setLink('/')
    setMedia([]);
    setActiveTab(1);
    setPage(defaultPageData);
  }

  const handleEdit = (e, data) => {
    e.stopPropagation();
    setPage({ ...defaultPageData, ...data });
    // setLink(link);
    setActiveTab(2);
  }

  const handleEditApplicant = (e, data) => {
    e.stopPropagation();
    setApplication({...defaultAppliedData, ...data});
    setActiveTab(4);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPage((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleDeleteMedia = async (file) => {
    const name = file.substring(file.lastIndexOf('/') + 1);

    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You will not be able to recover the Media",
      type: "warning",
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
    }).then(async (res) => {
      if (res.value) {
        try {
          await AdminService.deleteCareersMediaFromS3(page.id, name);

          const { result } = (await AdminService.getCareer(page.id))?.data;
          setPage(result[0]);

        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Delete failed',
            text: error?.response?.data?.message || error?.message || error.toString(),
          })
        }
      }
    })
  }
  return (
    <>
      <div className='fw-bold'>Publications</div>
      <hr />
      <Nav pills>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 1 })} onClick={() => setActiveTab(1)}>
            Career List
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 3 })} onClick={() => setActiveTab(3)}>
            Applicant List
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 2 })} onClick={() => setActiveTab(2)}>
            Add Career
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1} className={classNames(['fade show'])}>
          <Card body>
            <Table borderless striped responsive>
              <thead style={{ background: '#257479', color: '#fff', padding: '10px' }}>
                <tr>
                  <th style={{ padding: '10px' }}>Title</th>
                  <th style={{ padding: '10px' }}>College</th>
                  <th style={{ padding: '10px' }}>Created By</th>
                  <th style={{ padding: '10px' }}>Date</th>
                  <th style={{ padding: '10px' }}>Status</th>
                  <th style={{ padding: '10px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {pages.map((page) => {
                  return (
                    <tr key={page.id}>
                      <td>{page.jobTitle}</td>
                      <td>{page.type}</td>
                      <td>{page.createdby}</td>
                      <td>{page.createddate}</td>
                      <td><FontAwesomeIcon size='2xs' icon={faCircle} style={{ color: `${+page.status === 1 ? 'green' : 'red'}` }} /></td>
                      <td>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faEye} /></button> */}
                        <button className='btn btn-icon btn-sm' onClick={(e) => handleEdit(e, page)}><FontAwesomeIcon size='xs' icon={faPenToSquare} /></button>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faTrashCan} style={{ color: 'darkred' }} /></button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card>
          <CustomPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={(e, pageNumber) => {
            getData(pageNumber);
          }} />
        </TabPane>
        <TabPane tabId={3} className={classNames(['fade show'])}>
          <Card body>
            <Table borderless striped responsive>
              <thead style={{ background: '#257479', color: '#fff', padding: '10px' }}>
                <tr>
                  <th style={{ padding: '10px' }}>Career Id</th>
                  <th style={{ padding: '10px' }}>Name</th>
                  <th style={{ padding: '10px' }}>Email</th>
                  <th style={{ padding: '10px' }}>Experience</th>
                  <th style={{ padding: '10px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {appliedList.map((page) => {
                  return (
                    <tr key={page.id}>
                      <td>{page.careerId}</td>
                      <td>{page.firstname + ' ' + page.lastname}</td>
                      <td>{page.email}</td>
                      <td>{page.experience}</td>
                      {/* <td><FontAwesomeIcon size='2xs' icon={faCircle} style={{ color: `${+page.status === 1 ? 'green' : 'red'}` }} /></td> */}
                      <td>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faEye} /></button> */}
                        <button className='btn btn-icon btn-sm' onClick={(e) => handleEditApplicant(e, page)}><FontAwesomeIcon size='xs' icon={faEye} /></button>
                        {/* <button className='btn btn-icon btn-sm'><FontAwesomeIcon size='xs' icon={faTrashCan} style={{ color: 'darkred' }} /></button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card>
          <CustomPagination totalPages={totalApplicationPages} currentPage={currentApplicationPage} handlePageChange={(e, pageNumber) => {
            getApplicationList(pageNumber);
          }} />
        </TabPane>
        <TabPane tabId={2} className={classNames(['fade show'])}>
          <Card body>
            <Row className='gap-3'>
              <Col xl={9}>
                <Form>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>Title<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="jobTitle"
                        placeholder="Enter title here"
                        type="text"
                        value={page.jobTitle}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} for="Pages">Page Type</Label>
                    <Col sm={9}>
                      <select id="Pages" name='type' className='form-select' value={page.type} onChange={handleChange}>
                        <option value=''>Select</option>
                        <option value='ASRES'>ASRES</option>
                        <option value='MEDICAL'>MEDICAL</option>
                        <option value='NURSING'>NURSING</option>
                        <option value='PARAMEDICAL'>PARAMEDICAL</option>
                      </select>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail6" sm={3}>Job Role<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail5"
                        name="jobRole"
                        placeholder="Enter role here"
                        type="text"
                        value={page.jobRole}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail4" sm={3}>Job Posting Date<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail4"
                        name="jobPostingDate"
                        placeholder="Enter date here"
                        type="date"
                        value={page.jobPostingDate}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail3" sm={3}>Skills<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail3"
                        name="skills"
                        placeholder="Enter Skills here"
                        type="text"
                        value={page.skills}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={3}>Experience<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail2"
                        name="experience"
                        placeholder="Enter Date here"
                        type="text"
                        value={page.experience}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail1" sm={3}>Notice Period<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail1"
                        name="noticePeriod"
                        placeholder="Enter Date here"
                        type="text"
                        value={page.noticePeriod}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} >Description<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <CustomEditor value={page.jobDescription} onChange={(event, editor) => {
                        setPage((prevData) => ({
                          ...prevData,
                          jobDescription: editor.getData()
                        }))
                      }} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} >Media<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {media.map((file) => {
                          return (
                            <div key={file.name}>
                              {file.type.startsWith('image') && <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '200px' }} />}
                              {file.type === 'application/pdf' && <embed src={URL.createObjectURL(file)} type='application/pdf' width='300' height='300' />}
                              {file.type.startsWith('video') && <ReactPlayer url={URL.createObjectURL(file)} controls={true} playing width='300px' height='300px' />}
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} >Uploaded media<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {page.media && page.media.split(',').map((file, i) => {
                          return (
                            <div key={i}>
                              <GetMedia item={file} />
                              <Badge color="danger" onClick={() => handleDeleteMedia(file)}>X</Badge>
                              {/* <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                              </span> */}
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup
                    check
                    inline
                  >
                    <Input type="checkbox" checked={+page.status === 1} onChange={(e) => setPage({ ...page, status: e.target.checked ? 1 : 0 })} />
                    <Label check>
                      Check the box to enable the page or uncheck to disable
                    </Label>
                  </FormGroup>
                </Form>

              </Col>
            </Row>
            <br />
            <div>
              <Button style={{ background: '#257479' }} disabled={loading} onClick={handleSubmit}> {loading && <Spinner size='sm' />} Publish </Button>
              {" "}
              <Button color='light' style={{ border: '1px solid' }} disabled={loading} onClick={handleCancel}> Back </Button>
            </div>
          </Card>
        </TabPane>
        <TabPane tabId={4} className={classNames(['fade show'])}>
          <Card body>
            <Row className='gap-3'>
              <Col xl={9}>
                <Form>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>careerId<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.careerId}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>Email<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.email}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>firstname<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.firstname}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>Lastname<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.lastname}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>Experience<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.experience}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={3}>mobilenumber<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <Input
                        id="exampleEmail"
                        name="title"
                        placeholder="Enter title here"
                        type="text"
                        value={application.mobilenumber}
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  {/* <FormGroup row>
                    <Label sm={3} >Media<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {media.map((file) => {
                          return (
                            <div key={file.name}>
                              {file.type.startsWith('image') && <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '200px' }} />}
                              {file.type === 'application/pdf' && <embed src={URL.createObjectURL(file)} type='application/pdf' width='300' height='300' />}
                              {file.type.startsWith('video') && <ReactPlayer url={URL.createObjectURL(file)} controls={true} playing width='300px' height='300px' />}
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup> */}
                  <FormGroup row>
                    <Label sm={3} >Resume<span className="text-danger">*</span></Label>
                    <Col sm={9}>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {application.resumeUrl && application.resumeUrl.split(',').map((file, i) => {
                          return (
                            <div key={i}>
                              <GetMedia item={file} />
                              {/* <Badge color="danger" onClick={() => handleDeleteMedia(file)}>X</Badge> */}
                              {/* <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                              </span> */}
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup
                    check
                    inline
                  >
                    <Input type="checkbox" checked={+page.status === 1} onChange={(e) => setPage({ ...page, status: e.target.checked ? 1 : 0 })} />
                    <Label check>
                      Check the box to enable the page or uncheck to disable
                    </Label>
                  </FormGroup>
                </Form>

              </Col>
            </Row>
            <br />
            <div>
              <Button style={{ background: '#257479' }} disabled={loading} onClick={handleSubmit}> {loading && <Spinner size='sm' />} Publish </Button>
              {" "}
              <Button color='light' style={{ border: '1px solid' }} disabled={loading} onClick={handleCancel}> Back </Button>
            </div>
          </Card>
        </TabPane>
      </TabContent>
    </>
  )
}

export default Careers;