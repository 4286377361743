// import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Col, Form, FormGroup, Label, TabContent, TabPane } from 'reactstrap';
import { HeaderMenu } from './HeaderMenu';
import { defaultMenus, menuData } from './defaultValues';
import { TopMenu } from './TopMenu';
import { AdminService } from '../../services/AdminService';

function Navigation() {

  const [activeMenu, setActiveMenu] = useState(1);

  const [menus, setMenus] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        let menus = (await AdminService.getMenus())?.data?.result || [];
        menus = menus.reduce((result, m) => {
          result[m.title] = m;
          return result;
        }, {});

        setMenus(menus);
        
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='fw-bold'>Navigation</div>
      <hr />
      <div>
        <Form >
          <FormGroup row>
            <Label for="exampleEmail" sm={3}>Select a navigation edit<span className="text-danger">*</span></Label>
            <Col>
              <select className='form-select' value={activeMenu} onChange={(e) => setActiveMenu(e.target.value)}>
                <option value='0'>Select</option>
                {defaultMenus.map((menu) => {
                  return (
                    <option value={menu.id} key={menu.id}>{menu.name}</option>
                  )
                })}
              </select>
            </Col>
            <Col>
              {/* <Button style={{ background: '#257479' }} type='button'>Select</Button> */}
              {/* <span className='mx-2'>or</span> */}
              {/* <Button type='button' style={{ background: '#257479' }}>
                Create a new page
              </Button> */}
            </Col>
            <Col>
              {/* <p>Do not forget to save your changes!</p> */}
            </Col>
          </FormGroup>
        </Form>
      </div>
      <TabContent activeTab={+activeMenu}>
        <TabPane tabId={1}>
          <TopMenu data={menus['TopMenu'] || {...menuData, title: 'TopMenu' }} />
        </TabPane>
        <TabPane tabId={2}>
          <HeaderMenu data={menus['HeaderMenu'] || {...menuData, title: 'HeaderMenu' }} collapsible removable />
        </TabPane>
        <TabPane tabId={3}>
          <TopMenu data={menus['Medical TopMenu'] || {...menuData, title: 'Medical TopMenu' }} />
        </TabPane>
        <TabPane tabId={4}>
          <HeaderMenu data={menus['Medical HeaderMenu'] || {...menuData, title: 'Medical HeaderMenu' }} collapsible removable />
        </TabPane>
        <TabPane tabId={5}>
          <TopMenu data={menus['Nursing TopMenu'] || {...menuData, title: 'Nursing TopMenu' }} />
        </TabPane>
        <TabPane tabId={6}>
          <HeaderMenu data={menus['Nursing HeaderMenu'] || {...menuData, title: 'Nursing HeaderMenu' }} collapsible removable />
        </TabPane>
        <TabPane tabId={7}>
          <TopMenu data={menus['Paramedical TopMenu'] || {...menuData, title: 'Paramedical TopMenu' }} />
        </TabPane>
        <TabPane tabId={8}>
          <HeaderMenu data={menus['Paramedical HeaderMenu'] || {...menuData, title: 'Paramedical HeaderMenu' }} collapsible removable />
        </TabPane>
        <TabPane tabId={9}>
          <TopMenu data={menus['FooterMenu'] || {...menuData, title: 'FooterMenu' }} />
        </TabPane>
        <TabPane tabId={10}>
          <TopMenu data={menus['Medical FooterMenu'] || {...menuData, title: 'Medical FooterMenu' }} />
        </TabPane>
        <TabPane tabId={11}>
          <TopMenu data={menus['Nursing FooterMenu'] || {...menuData, title: 'Nursing FooterMenu' }} />
        </TabPane>
        <TabPane tabId={12}>
          <TopMenu data={menus['Paramedical FooterMenu'] || {...menuData, title: 'Paramedical FooterMenu' }} />
        </TabPane>
        <TabPane tabId={13}>
          <HeaderMenu data={menus['Medical Sidebar'] || {...menuData, title: 'Medical Sidebar' }} collapsible removable />
        </TabPane>
      </TabContent>

    </>
  )
}

export default Navigation;