import classNames from 'classnames'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Container } from 'reactstrap'
import TopBar from './TopBar'
import { routesData } from '../routes'
import AdminFooter from '../AdminFooter'

function Main({ sidebarIsOpen, toggleSidebar, setLogin }) {
  return (
    <Container
      fluid
      className={classNames("px-5 content", { "is-open": sidebarIsOpen })}
    >
      <TopBar toggleSidebar={toggleSidebar} setLogin={setLogin} />
      <hr />
      <div style={{ minHeight: '75%' }}>
        <Routes>
          {routesData.map(({ Component, link, title }) => {
            return (
              <Route exact path={link} element={Component} key={title} />
            )
          })}
          <Route exact path="/" element={<Navigate to='/landingPage' replace />} />
        </Routes>
      </div>
      <AdminFooter />
    </Container>
  )
}

export default Main;