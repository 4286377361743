import axios from "axios";

const AUTH_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_AUTH_SERVICE_PORT;
const USER_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_USER_SERVICE_PORT;

const login = (username, password) => {
    const customConfig = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return axios
        .post(`${AUTH_API}auth/login`, { username, password }, customConfig)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const logout = (id) => {
    if (JSON.parse(localStorage.getItem("user"))?.id === id) {
        localStorage.removeItem("user");
    }
    return axios
        .get(`${AUTH_API}auth/logout/${id}`)
        .then((response) => {
            return response.data;
        });
};

const currentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const isUserLoggedIn = () => {
    return Boolean(localStorage.getItem("user"));
};

const resetPassword = (username) => {
    return axios.get(`${USER_API}user/resetpassword/${username}`
    );
  };

export const AuthService = {
    login,
    logout,
    currentUser,
    isUserLoggedIn,
    resetPassword
}