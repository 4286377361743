import React, { forwardRef } from "react";
import { Remove } from "../HeaderMenu/Remove";

const Item = forwardRef(({ id, ...props }, ref) => {
    return (
        <div

            style={{
                display: "flex",
                padding: "10px",
                margin: "10px",
                border: "1px solid #dedede",
                background: "#fff",
                alignItems: 'center'
            }}
        >
            <span {...props} ref={ref} style={{ flexGrow: '1' }}>
                {props.name}
            </span>
            {props.handleRemove && <Remove onClick={props.handleRemove} />}
        </div>
    );
});

export default Item;
