import React, { useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";
import Item from "./Item";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { defaultPages, menuData } from "../defaultValues";
import { v4 as uuid } from "uuid";
import { AdminService } from "../../../services/AdminService";
import { removeItem } from "../HeaderMenu/utilities";
import Swal from "sweetalert2";

const defaultItem = {
  id: "",
  name: "",
  disable: true,
  link: "",
  editable: true,
  children: [],
};

export function TopMenu({ data }) {
  const [activeId, setActiveId] = useState(null);
  const [activeName, setActiveName] = useState(null);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [headerMenu, setHeaderMenu] = useState(menuData);

  const [itemLocation, setItemLocation] = useState([]);

  const [newItem, setNewItem] = useState(defaultItem);

  const [pageOpen, setPageOpen] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setHeaderMenu(data);
        setItems(getMenu(data.description));
      } catch (e) {
        console.log(e);
      }
    };

    const getMenu = (m) => {
      try {
        const menu = JSON.parse(m);
        return menu;
      } catch(e) {
        // console.log(e);
        return [];
      }
    }

    fetchData();
  }, [data]);

  const toggle = () => setModal(!modal);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleModel = (e) => {
    let { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const submitModel = (e) => {
    e.stopPropagation();
    if(!newItem.name) {
      Swal.fire({
        icon: "error",
        title: "Submit failed",
        text: "Please enter page title"
      });
      return;
    }
    if (itemLocation.length === 0) {
      setItems([...items, { ...newItem, id: uuid() }]);
    } else if (itemLocation.length === 1) {
      const d = structuredClone(items);
      d[itemLocation[0]].id = newItem.id;
      d[itemLocation[0]].name = newItem.name;
      d[itemLocation[0]].link = newItem.link;
      setItems(d);
    } else if (itemLocation.length === 2) {
      const d = structuredClone(items);
      d[itemLocation[0]].children[itemLocation[1]].id = newItem.id;
      d[itemLocation[0]].children[itemLocation[1]].name = newItem.name;
      d[itemLocation[0]].children[itemLocation[1]].link = newItem.link;
      setItems(d);
    }
    setItemLocation([]);
    toggle();
    setNewItem(defaultItem);
  };

  function handleRemove(id) {
    setItems((items) => removeItem(items, id));
  }

  const submitMenu = async (e) => {
    e.stopPropagation();
    const getMenu = structuredClone(headerMenu);
    getMenu.description = JSON.stringify(items);
    getMenu.media = "";

    try {
      await AdminService.saveMenu(getMenu);
      Swal.fire({
        icon: "success",
        title: `Updated successfully`,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Submit failed",
        text:
          error?.response?.data?.message || error?.message || error.toString(),
      });
    }
  };

  return (
    <>
      <Card body>
        <Row>
          <Col sm={4}>
            <div className="fw-bold">Add Menu Items</div>
            <br />
            <div style={{ position: "relative" }}>
              <Card>
                <div className="">
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2 border"
                    onClick={() => setPageOpen(!pageOpen)}
                  >
                    Pages
                    <Button
                      color="light"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setNewItem(defaultItem);
                        toggle();
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    {pageOpen ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretUp} />
                    )}
                  </div>
                  <Collapse isOpen={pageOpen} style={{ background: "#e9ecef" }}>
                    {items.map((item, i) => {
                      return (
                        <ListGroupItem
                          className="px-3 py-2"
                          key={i}
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <Input
                              type="checkbox"
                              checked={!item.disable}
                              onChange={(e) => {
                                const defaultItems = structuredClone(items);
                                defaultItems[i].disable = !e.target.checked;
                                setItems(defaultItems);
                              }}
                            />
                            {item.name}{" "}
                            <Button
                              color="light"
                              style={{
                                padding: "0",
                                background: "transparent",
                                border: "none",
                              }}
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                setNewItem({ ...item, children: [] });
                                setItemLocation([i]);
                                toggle();
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} size="sm" />
                            </Button>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>
                </div>
              </Card>
            </div>
          </Col>
          <Col sm={8}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map((item) => {
                  if(!item.disable) {
                      return (
                        <SortableItem
                          handleRemove={(e) => {
                            e.stopPropagation();
                            handleRemove(item.id);
                          }}
                          key={item.id}
                          id={item.id}
                          name={item.name}
                        />
                      );
                  } else return null;
                })}
              </SortableContext>
              <DragOverlay>
                {activeId ? <Item id={activeId} name={activeName} /> : null}
              </DragOverlay>
            </DndContext>
            <hr />
            <FormGroup>
              <Button
                style={{ background: "#257479" }}
                type="button"
                onClick={submitMenu}
              >
                Submit
              </Button>
              {"  "}
              <Button color="light" type="button">
                Cancel
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>New Menu</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="Pages">Pages</Label>
              <select
                id="Pages"
                className="form-select"
                value={newItem.link}
                onChange={(e) => {
                  const { value } = e.target;
                  setNewItem({
                    ...newItem,
                    link: value,
                    editable: value === "#" ? true : false,
                  });
                }}
              >
                {/* <option value=''>Select</option> */}
                {defaultPages.map((page, index) => {
                  return (
                    <option value={page.link} key={index}>
                      {page.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Title</Label>
              <Input
                id="exampleEmail"
                name="name"
                placeholder="Enter page name"
                type="text"
                value={newItem.name}
                onChange={handleModel}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Link</Label>
              <Input
                id="examplePassword"
                name="link"
                placeholder="Page location"
                type="text"
                disabled={!newItem.editable}
                value={newItem.link}
                onChange={handleModel}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitModel}>
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

  function handleDragStart(event) {
    const { active } = event;
    const name = items.find((item) => item.id === active.id)?.name || "";

    setActiveId(active.id);
    setActiveName(name);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const overIndex = items.findIndex(({ id }) => id === over.id);
        const activeIndex = items.findIndex(({ id }) => id === active.id);

        return arrayMove(items, activeIndex, overIndex);
      });
    }

    setActiveId(null);
    setActiveName(null);
  }
}
