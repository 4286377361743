import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';


function InputFile({ id, data, media, onFileChange, name }) {
    return (
        (!data?.media ?
            <div className="col d-flex flex-column justify-content-center">
                <label className="col-form-label">{media ? '  (Re-upload to select the below image)' : ''}</label>
                <label className="form-control text-center" style={{ border: `${media ? 'none' : ''}` }} htmlFor={id}>{!media ?
                    <>Click here to upload image</> :
                    <img src={URL.createObjectURL(media)} alt='book cover' width='150' height='150' />}
                </label>
                <input className='coverpage' onClick={(e) => e.target.value = null} name={name || 'media'} type="file" accept="image/* application/pdf" capture="camera" max="1" id={id} onChange={onFileChange} />
            </div>
            :
            <div className="col d-flex flex-column justify-content-center" style={{ alignitems: 'center', textAlign: 'center' }}>
                <label className="col-form-label">{media || data.media ? '  (Re-upload to select the below image)' : ''}</label>
                <label className="form-control text-center" style={{ border: `${media || data.media ? 'none' : ''}` }} htmlFor={id}>
                    {data.media || media ? <img src={(media && URL.createObjectURL(media)) || data.media} alt='book cover' alignitems='center' width='150' height='150' /> :
                        <>Click here to upload image</>}
                </label>
                <input className='coverpage' onClick={(e) => e.target.value = null} name={name || 'media'} type="file" accept="image/* application/pdf" capture="camera" max="1" id={id} onChange={onFileChange} />
            </div>
        )
    )
}

const CustomEditor = ({ value, onChange }) => {
    const handleChange = (event, editor) => {
        // console.log({value, data: editor.getData()});
        // if(value && value !== editor.getData()) {
            onChange(event, editor);
        // }
    }
    return (
        ClassicEditor ?
            <CKEditor id='description' editor={ClassicEditor} data={value} height='500' onChange={handleChange} />
            : <>Loading</>

    )
}

const delay = (ms) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

export { InputFile, CustomEditor, delay };

