// import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CustomPagination = ({ totalPages, currentPage, handlePageChange }) => {
    //   const [currentPage, setCurrentPage] = useState(1);
    const maxVisiblePages = 10;

    // Calculate total number of pages
    //   const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Function to handle page change
    //   const handlePageChange = (page) => {
    //     setCurrentPage(page);
    //   };

    // Generate array of page numbers to display
    const getPageNumbers = () => {
        const pageNumbers = [];
        // const visiblePages = Math.min(totalPages, maxVisiblePages);

        if (totalPages <= maxVisiblePages) {
            // Display all pages if total pages are less than or equal to maxVisiblePages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Display starting and ending pages with ellipsis in between
            const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1));
            const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }

            // Add ellipsis if necessary
            if (startPage > 1) {
                pageNumbers.unshift('...');
            }
            if (endPage < totalPages) {
                pageNumbers.push('...');
            }
        }

        return pageNumbers;
    };

    return (
        <Pagination style={{ justifyContent: 'center', margin: '10px 0'}}>
            {getPageNumbers().map((page, index) => (
                <PaginationItem key={index} active={typeof page === 'number' && page === currentPage}>
                    <PaginationLink
                        onClick={(e) => {
                            if (typeof page === 'number') {
                                handlePageChange(e, page);
                            }
                        }}
                    >
                        {typeof page === 'number' ? page : '...'}
                    </PaginationLink>
                </PaginationItem>
            ))}
        </Pagination>
    );
};

export default CustomPagination;
