import { faAlignLeft, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar } from 'reactstrap'
import collegeName from '../../assets/images/collegeName.png';
import { AuthService } from '../../services/AuthServices';

function TopBar({ toggleSidebar, setLogin }) {
  const [topBarIsOpen, setTopBarOpen] = useState(false);
  const toggleTopBar = () => setTopBarOpen(!topBarIsOpen);

  const user = AuthService.currentUser();
  const handleLogout = async () => {
    try {
      await AuthService.logout(user.id);
      setLogin(false);
    } catch(e) {
      console.log(e);
      setLogin(true);
    }
  }

  return (
    <Navbar light className="navbar mb-2" expand="md">
      <div>
        <Button style={{ background: '#257479' }} onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <img src={collegeName} alt='collageName' height={20} style={{ marginLeft: '15px' }} />
      </div>
      <Dropdown isOpen={topBarIsOpen} toggle={toggleTopBar} direction='down' color='light '>
        <DropdownToggle caret color='light'><FontAwesomeIcon icon={faUserSecret} color='blue' /> {user?.firstname + user?.lastname}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Profile</DropdownItem>
          <DropdownItem onClick={handleLogout}>Log out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Navbar>
  )
}

export default TopBar