import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    faCaretDown,
    faCaretUp,
    faEdit,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Badge,
    Button,
    Card,
    Col,
    Collapse,
    Form,
    FormGroup,
    Input,
    Label,
    // ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { createPortal } from "react-dom";
import {
    // Announcements,
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
    // DragStartEvent,
    DragOverlay,
    // DragMoveEvent,
    // DragEndEvent,
    // DragOverEvent,
    MeasuringStrategy,
    // DropAnimation,
    defaultDropAnimation,
    // Modifier,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
    buildTree,
    flattenTree,
    getProjection,
    getChildCount,
    removeItem,
    removeChildrenOf,
    setProperty,
} from "./utilities";
import { SortableTreeItem } from "./SortableTreeItem";
import { AdminService } from "../../../services/AdminService";
import { v4 as uuid } from "uuid";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";

const defaultItem = {
    id: "",
    disable: true,
    link: "",
    title: '',
    type: 'folder',
    editable: true,
    media: [],
    uploadMedia: [],
    children: [],
};

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const dropAnimation = {
    ...defaultDropAnimation,
    dragSourceOpacity: 0.5,
};

export function FolderFile({
    collapsible,
    indicator,
    indentationWidth = 20,
    removable,
    data: items = [],
    pageId,
    handleDescription: setItems,
}) {
    // const [items, setItems] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [modal, setModal] = useState(false);
    // const [headerMenu, setHeaderMenu] = useState({});

    const toggle = () => setModal(!modal);

    const [newItem, setNewItem] = useState(defaultItem);
    const [itemLocation, setItemLocation] = useState([]);

    const [pageOpen, setPageOpen] = useState(true);

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items);
        const collapsedItems = flattenedTree.reduce(
            (acc, { children, collapsed, id }) =>
                collapsed && children.length ? [...acc, id] : acc,
            []
        );

        return removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems
        );
    }, [items, activeId]);

    const projected =
        activeId && overId
            ? getProjection(
                flattenedItems,
                activeId,
                overId,
                offsetLeft,
                indentationWidth
            )
            : null;
    const sensorContext = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });
    const sensors = useSensors(
        useSensor(PointerSensor)
        // useSensor(KeyboardSensor, {
        //   coordinateGetter,
        // })
    );

    const onFileChange = (e) => {
        // console.log(e);
        // const { files } = e.target;
        setNewItem((prevData) => ({
            ...prevData,
            uploadMedia: e
        }));
    };

    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/msword': [],
            'application/xslx': ['.xlsx', '.xls']
        },
        onDrop: onFileChange
    });

    const sortedIds = useMemo(
        () => flattenedItems.map(({ id }) => id),
        [flattenedItems]
    );
    const activeItem = activeId
        ? flattenedItems.find(({ id }) => id === activeId)
        : null;

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             setHeaderMenu(data);
    //             setItems(getMenu(data.description));
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     };

    //     const getMenu = (m) => {
    //         try {
    //             const menu = JSON.parse(m);
    //             return menu;
    //         } catch (e) {
    //             //   console.log(e);
    //             return [];
    //         }
    //     }

    //     fetchData();
    // }, [data]);

    const announcements = {
        onDragStart(id) {
            return `Picked up ${id}.`;
        },
        onDragMove(id, overId) {
            return getMovementAnnouncement("onDragMove", id, overId);
        },
        onDragOver(id, overId) {
            return getMovementAnnouncement("onDragOver", id, overId);
        },
        onDragEnd(id, overId) {
            return getMovementAnnouncement("onDragEnd", id, overId);
        },
        onDragCancel(id) {
            return `Moving was cancelled. ${id} was dropped in its original position.`;
        },
    };

    const adjustTranslate = ({ transform }) => {
        return {
            ...transform,
            y: transform.y - 25,
        };
    };
    const handleModel = (e) => {
        let { name, value } = e.target;
        setNewItem({
            ...newItem,
            [name]: value,
        });
    };

    const submitModel = (e) => {
        e.stopPropagation();
        if (!newItem.name) {
            Swal.fire({
                icon: "error",
                title: "Submit failed",
                text: "Please enter page title"
            });
            return;
        }

        if (itemLocation.length === 0) {
            setItems([...items, { ...newItem, id: uuid() }]);
        } else {
            const d = structuredClone(items);
            const updatedItems = getUpdatedItems(d, itemLocation);
            setItems(updatedItems);
        }
        setItemLocation([]);
        toggle();
        setNewItem(defaultItem);
    };

    const getUpdatedItems = (d, itemLoc) => {
        if (itemLocation[0] !== undefined) {
            const [, ...rest] = itemLoc;
            if (rest.length === 0) {
                const children = d[itemLoc[0]].children;
                d[itemLoc[0]] = {
                    ...d[itemLoc[0]],
                    ...newItem,
                    children: children,
                }
                return d;
            }
            getUpdatedItems(d[itemLoc[0]].children, rest)
        }

        return d;
    }

    // const submitMenu = async (e) => {
    //     e.stopPropagation();
    //     // const getMenu = structuredClone(headerMenu);
    //     // getMenu.description = JSON.stringify(items);
    //     // getMenu.media = '';

    //     try {
    //         // await AdminService.saveMenu(getMenu);
    //         Swal.fire({
    //             icon: 'success',
    //             title: `Updated successfully`,
    //             showConfirmButton: false,
    //             timer: 2000
    //         });
    //     } catch (error) {
    //         console.log(error);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Submit failed',
    //             text: error?.response?.data?.message || error?.message || error.toString(),
    //         })
    //     }
    // }

    const handleDeleteMedia = async (file, index) => {
        const name = file.substring(file.lastIndexOf('/') + 1);

        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover the Media",
            type: "warning",
            allowEscapeKey: true,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
        }).then(async (res) => {
            if (res.value) {
                try {
                    await AdminService.deleteMediaFromS3(pageId, name);
                    let k = [...newItem.media];
                    k.splice(index, 1);
                    setNewItem((prevData) => ({
                        ...prevData,
                        media: k,
                    }))

                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Delete failed',
                        text: error?.response?.data?.message || error?.message || error.toString(),
                    })
                }
            }
        })
    }


    const getNestedList = (items, location) => {
        return (
            items.map((item, i) => {
                return (
                    <ListGroupItem
                        className="px-3 py-2"
                        key={i}
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                        }}
                    >
                        <div>
                            <Input
                                type="checkbox"
                                checked={!item.disable}
                                onChange={(e) => {
                                    const defaultItems = structuredClone(items);
                                    defaultItems[i].disable = !e.target.checked;
                                    setItems(defaultItems);
                                }}
                            />
                            {item.name}{" "}
                            <Button
                                color="light"
                                style={{
                                    padding: "0",
                                    background: "transparent",
                                    border: "none",
                                }}
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setNewItem({ ...item, children: [] });
                                    setItemLocation([...location, i]);
                                    toggle();
                                }}
                            >
                                <FontAwesomeIcon icon={faEdit} size="sm" />
                            </Button>
                        </div>
                        {getNestedList(item.children, [...location, i])}
                    </ListGroupItem>
                )
            })
        )
    }

    return (
        <>
            <Card body>
                <Row>
                    <Col sm={4}>
                        <div style={{ position: "relative" }}>
                            <Card>
                                <div className="">
                                    <div
                                        className="d-flex justify-content-between align-items-center px-3 py-2 border"
                                        onClick={() => setPageOpen(!pageOpen)}
                                    >
                                        File Manager
                                        <Button
                                            color="light"
                                            type="button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setNewItem(defaultItem);
                                                toggle();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                        {pageOpen ? (
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        ) : (
                                            <FontAwesomeIcon icon={faCaretUp} />
                                        )}
                                    </div>
                                    <Collapse isOpen={pageOpen} style={{ background: "#e9ecef" }}>
                                        {getNestedList(items, [])}
                                    </Collapse>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <DndContext
                            announcements={announcements}
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            measuring={measuring}
                            onDragStart={handleDragStart}
                            onDragMove={handleDragMove}
                            onDragOver={handleDragOver}
                            onDragEnd={handleDragEnd}
                            onDragCancel={handleDragCancel}
                        >
                            <SortableContext
                                items={sortedIds}
                                strategy={verticalListSortingStrategy}
                            >
                                {flattenedItems.map(
                                    ({ id, name, children, collapsed, depth, disable }) => {
                                        if (!disable) {
                                            return (
                                                <SortableTreeItem
                                                    key={id}
                                                    id={id}
                                                    value={name}
                                                    depth={
                                                        id === activeId && projected
                                                            ? projected.depth
                                                            : depth
                                                    }
                                                    indentationWidth={indentationWidth}
                                                    indicator={indicator}
                                                    collapsed={Boolean(collapsed && children.length)}
                                                    onCollapse={
                                                        collapsible && children.length
                                                            ? () => handleCollapse(id)
                                                            : undefined
                                                    }
                                                    onRemove={
                                                        removable ? () => handleRemove(id) : undefined
                                                    }
                                                />
                                            );
                                        } else return null;
                                    }
                                )}
                                {createPortal(
                                    <DragOverlay
                                        dropAnimation={dropAnimation}
                                        modifiers={indicator ? [adjustTranslate] : undefined}
                                    >
                                        {activeId && activeItem ? (
                                            <SortableTreeItem
                                                id={activeId}
                                                depth={activeItem.depth}
                                                clone
                                                childCount={getChildCount(items, activeId) + 1}
                                                value={activeItem.name}
                                                indentationWidth={indentationWidth}
                                            />
                                        ) : null}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </DndContext>
                        {/* <hr />
                        <FormGroup>
                            <Button style={{ background: "#257479" }} type="button" onClick={submitMenu}>
                                Submit
                            </Button>
                            {"  "}
                            <Button color="light" type="button">
                                Cancel
                            </Button>
                        </FormGroup> */}
                    </Col>
                </Row>
            </Card>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>New Menu</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="Pages">Type</Label>
                            <select id="Pages" className='form-select' value={newItem.type} onChange={(e) => {
                                const { value } = e.target;
                                setNewItem((prevData) => ({ ...prevData, type: value, }));

                            }}>
                                <option value='folder'>Folder</option>
                                <option value='file'>File</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Title</Label>
                            <Input
                                id="exampleEmail"
                                name="name"
                                placeholder="Enter page name"
                                type="text"
                                value={newItem.name}
                                onChange={handleModel}
                            />
                        </FormGroup>
                        {newItem.type === 'file' && <FormGroup>
                            <Label for="examplePassword">Media</Label>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p style={{ border: '1px solid', borderRadius: '10px', padding: '20px', background: 'aliceblue', textAlign: 'center' }}>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                {newItem.uploadMedia && newItem.uploadMedia.map((file, i) => {
                                    return (
                                        <div key={i}>
                                            <p>{file.name}</p>
                                            {/* <Badge color="danger" onClick={() => handleDeleteMedia(file)}>X</Badge> */}
                                        </div>
                                    )
                                })}
                            </div>
                        </FormGroup>}
                        {newItem.media.length > 0 && <FormGroup row>
                            <Label sm={2} >Uploaded media<span className="text-danger">*</span></Label>
                            <Col sm={10}>
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    {newItem.media.map((file, i) => {
                                        return (
                                            <div key={i}>
                                                <p>{file}</p>
                                                <Badge color="danger" onClick={() => handleDeleteMedia(file, i)}>X</Badge>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </FormGroup>}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitModel}>
                        Submit
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );

    function handleDragStart({ active: { id: activeId } }) {
        setActiveId(activeId);
        setOverId(activeId);

        const activeItem = flattenedItems.find(({ id }) => id === activeId);

        if (activeItem) {
            setCurrentPosition({
                parentId: activeItem.parentId,
                overId: activeId,
            });
        }

        document.body.style.setProperty("cursor", "grabbing");
    }

    function handleDragMove({ delta }) {
        setOffsetLeft(delta.x);
    }

    function handleDragOver({ over }) {
        setOverId(over?.id ?? null);
    }

    function handleDragEnd({ active, over }) {
        resetState();

        if (projected && over) {
            const { depth = 0, parentId } = projected;
            const clonedItems = structuredClone(flattenTree(items));
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
            const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);

            setItems(newItems);
        }
    }

    function handleDragCancel() {
        resetState();
    }

    function resetState() {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty("cursor", "");
    }

    function handleRemove(id) {
        setItems((items) => removeItem(items, id));
    }

    function handleCollapse(id) {
        setItems((items) =>
            setProperty(items, id, "collapsed", (value) => {
                return !value;
            })
        );
    }

    function getMovementAnnouncement(eventName, activeId, overId) {
        if (overId && projected) {
            if (eventName !== "onDragEnd") {
                if (
                    currentPosition &&
                    projected.parentId === currentPosition?.parentId &&
                    overId === currentPosition?.overId
                ) {
                    return;
                } else {
                    setCurrentPosition({
                        parentId: projected.parentId,
                        overId,
                    });
                }
            }

            const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
            const overIndex = clonedItems.findIndex(({ id }) => id === overId);
            const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];

            let announcement;
            const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
            const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
                } else {
                    let previousSibling = previousItem;
                    while (previousSibling && projected.depth < previousSibling.depth) {
                        const parentId = previousSibling.parentId;
                        previousSibling = sortedItems.find(({ id }) => id === parentId);
                    }

                    if (previousSibling) {
                        announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
                    }
                }
            }

            return announcement;
        }

        return;
    }
}
